<!-- =========================================================================================
File Name: RegisterJWT.vue
Description: Register Page for JWT
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="clearfix">
    <vs-input
      v-validate="'required|alpha_dash|min:3'"
      v-model="first_name"
      data-vv-validate-on="blur"
      label-placeholder="First Name"
      name="first_name"
      placeholder="First Name"
      class="w-full"
    />
    <span 
      class="text-danger text-sm"
    >{{ error["first_name"] }} {{ errors.first("first_name") }}</span
    >

    <vs-input
      v-validate="'required|alpha_dash|min:3'"
      v-model="last_name"
      data-vv-validate-on="blur"
      label-placeholder="Last Name"
      name="last_name"
      placeholder="Last Name"
      class="w-full mt-6"
    />
    <span 
      class="text-danger text-sm"
    >{{ error["last_name"] }} {{ errors.first("last_name") }}</span
    >

    <vs-input
      v-validate="'required|email'"
      v-model="email"
      data-vv-validate-on="blur"
      name="email"
      type="email"
      label-placeholder="Email"
      placeholder="Email"
      class="w-full mt-6"
    />
    <span 
      class="text-danger text-sm"
    >{{ error["email"] }} {{ errors.first("email") }}</span
    >

    <vs-input
      v-validate="'required|numeric'"
      v-model="phone"
      data-vv-validate-on="blur"
      name="phone"
      type="phone"
      label-placeholder="Phone Number (Username)"
      placeholder="Phone Number"
      class="w-full mt-6"
    />
    <span 
      class="text-danger text-sm"
    >{{ error["username"] }} {{ errors.first("phone") }}</span
    >

    <vs-input
      v-validate="'required|min:6|max:10'"
      ref="password"
      v-model="password"
      type="password"
      data-vv-validate-on="blur"
      name="password"
      label-placeholder="Password"
      placeholder="Password"
      class="w-full mt-6"
    />
    <span 
      class="text-danger text-sm"
    >{{ error["password1"] }} {{ errors.first("password") }}</span
    >

    <vs-input
      v-validate="'min:6|max:10|confirmed:password'"
      v-model="confirm_password"
      type="password"
      data-vv-validate-on="blur"
      data-vv-as="password"
      name="confirm_password"
      label-placeholder="Confirm Password"
      placeholder="Confirm Password"
      class="w-full mt-6"
    />
    <span 
      class="text-danger text-sm"
    >{{ error["password2"] }} {{ errors.first("confirm_password") }}</span
    >

    <vs-checkbox 
      v-model="isTermsConditionAccepted" 
      class="mt-6"
    >I accept the terms & conditions.</vs-checkbox
    >
    <vs-button 
      type="border" 
      to="/pages/login" 
      class="mt-6">Login</vs-button>
    <vs-button
      :disabled="!validateForm"
      class="float-right mt-6"
      @click="registerUserJWt"
    >Register</vs-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
      confirm_password: "",
      referer: "",
      isTermsConditionAccepted: true,
      error: {},
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.first_name != "" &&
        this.last_name != "" &&
        this.email != "" &&
        this.phone != "" &&
        this.password != "" &&
        this.confirm_password != "" &&
        this.isTermsConditionAccepted === true
      );
    },
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: "Login Attempt",
          text: "You are already logged in!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });

        return false;
      }
      return true;
    },
    registerUserJWt() {
      // If form is not validated or user is already login return
      if (!this.validateForm || !this.checkLogin()) return;

      this.$vs.loading();

      const payload = {
        userDetails: {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          phone: this.phone,
          password: this.password,
          confirmPassword: this.confirm_password,
        },
        notify: this.$vs.notify,
      };
      this.error = {};
      this.$store
        .dispatch("auth/registerUserJWT", payload)
        .then((response) => {
          this.$vs.loading.close();
          if (response.data.errors) {
            this.error = response.data.errors;
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
  },
};
</script>
